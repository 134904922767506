import './paintings.css';
import Carousel from 're-carousel';
import IndicatorDots from '../slides/indicator-dots';

function Paintings() { /**Buttons*/
  return (
    <div className="paintings">
      <Carousel auto loop widgets={[IndicatorDots]}>
        <Slide img={"../../imgs/1.webp"}>
          <div className='description-painting'>
            <div>Копия картины</div>
            <div>Зинаиды Серебряковой</div>
            <div>«За завтраком» 2022</div>
            <div className='size-painting'>50x40</div>
          </div>
        </Slide>
        <Slide img={"../../imgs/2.webp"}>
          <div className='description-painting'>
            <div>«Тигр» 2022</div>
            <div className='size-painting'>60x40</div>
          </div>
        </Slide>
        <Slide img={"../../imgs/3.webp"}>
          <div className='description-painting'>
            <div>Копия картины</div>
            <div>Кассиуса Кулиджа</div>
            <div>«Игра в покер» 2022</div>
            <div className='size-painting'>50x40</div>
          </div>
        </Slide>
        <Slide img={"../../imgs/4.webp"}>
          <div className='description-painting'>
            <div>«Панелька» 2020</div>
            <div className='size-painting'>40x40</div>
          </div>
        </Slide>
      </Carousel>
    </div>
  );
}

function Slide({ img, children }) {
  return (
    <div className="paintingsSlide">
      <div className='innerPaintings'>
        <div className='paintingsLeft'>
          <img className='paintingsImg' alt='element' src={img}></img>
        </div>

        <div className='paintingsRight'>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Paintings;
