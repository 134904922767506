import { ReactTyped } from 'react-typed';
import './main.css';

function Main() {
  return (
    <div className="main">
        <div className='innerMain'>
            <div className='innerLeft'>
                <div>ИСКУССТВО,</div>
                <div>КОТОРОЕ</div> 
                <div style={{color: "white"}}>
                  <ReactTyped 
                    strings={["ГОВОРИТ","ПОБУЖДАЕТ","ВДОХНОВЛЯЕТ","ЗАВОРАЖИВАЕТ","УСПОКАИВАЕТ"]} 
                    typeSpeed={150}
                    loop />
                </div>
            </div>

            <div className='innerRight'>
                <div>Аутентичные картины на заказ</div>
                <div>от художницы Марии из Саратова</div>
            </div>
        </div>
    </div>
  );
}

export default Main;
