import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Main from './components/main/main';
import Paintings from './components/paintings/paintings';
import ToysMain from './components/toys_main/toys_main';
import Toys from './components/toys/toys';
import Contacts from './components/contacts/contacts';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Main />
    <Paintings />
    <ToysMain />
    <Toys />
    <Contacts />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
