import './contacts.css';

function Contacts() {
  return (
    <div className="contacts">
      <div className="contactsLeft">
        <div>Что-то приглянулось?</div>
        <div>Уточните наличие представленных на сайте экземпляров.</div>
        <div>Или закажите работу с индивидуальным дизайном.</div>
      </div>
      <div className="contactsRight">
        <div>Нам можно написать <a className='link-a' target='_blank' href='https://t.me/lucern_etc'>сюда</a> <img className='img-a' src="../../imgs/tg.webp"></img>.
          </div>
        <div>А можно <a className='link-a' target='_blank' href='https://vk.com/lucern_art'>сюда</a> <img className='img-a' src="../../imgs/vk.webp"></img>.
          </div>
      </div>
    </div>
  );
}

export default Contacts;
