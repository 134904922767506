import './toys.css';
import Carousel from 're-carousel';
import IndicatorDots from '../slides/indicator-dots';

function Toys() {
  return (
    <div className="toys">
      <Carousel auto loop widgets={[IndicatorDots]}>
        <Slide img={"../../imgs/t0.webp"}>
          <div className='description-toys'>
            <div>Нашёл свой дом</div>
          </div>
        </Slide>
        <Slide img={"../../imgs/t1.webp"}>
          <div className='description-toys'>
            <div>Нашёл добрые руки</div>
          </div>
        </Slide>
        <Slide img={"../../imgs/t2.webp"}>
          <div className='description-toys'>
            <div>Попал в хорошую семью</div>
          </div>
        </Slide>
        <Slide img={"../../imgs/t4.webp"}>
          <div className='description-toys'>
            <div>Ждёт добрые руки</div>
          </div>
        </Slide>
        <Slide img={"../../imgs/t3.webp"}>
          <div className='description-toys'>
            <div>Обрёл счастье в семье</div>
          </div>
        </Slide>
        <Slide img={"../../imgs/t5.webp"}>
          <div className='description-toys'>
            <div>Ждёт добрые руки</div>
          </div>
        </Slide>
      </Carousel>
    </div>
  );
}

function Slide({ img, children }) {
  return (
    <div className="toysSlide">
      <div className='innerToys'>
      <div className='toysRight'>
          {children}
        </div>
        <div className='toysLeft'>
          <img className='toysImg' alt='element' src={img}></img>
        </div>
      </div>
    </div>
  );
}

export default Toys;
