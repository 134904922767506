import './toys_main.css';

function ToysMain() {
  return (
    <div className="toysMain">
        <div className='toysMainInner'>
            <div className='toysMainLeft'>
              <div>Коллекция ручной работы</div>
            </div>

            <div className='toysMainRight'>
              <div>СТРАШНЫЕ</div>
              <div>ИГРУШКИ</div>
              <div>В ДОБРЫЕ</div>
              <div>РУКИ</div>
            </div>
        </div>
    </div>
  );
}

export default ToysMain;
